import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { AuthContext } from "./context/AuthProvider";
import Layout from "./container/Layout/Layout";
import {
    HOME_PAGE,
    VERIFY_PAGE,
    VERIFIED_PAGE,
    UPLOAD_PAGE,
    VERIFICATION_PAGE,
    APPROVE_PAGE,
    DOCTOR_PROFILE_PAGE,
    DOCTOR_DASHBOARD_PAGE,
    DOCTOR_REVIEW_PAGE,
    DOCTOR_PREVIEW_PAGE,
    PRIVACY_PAGE,
    LISTING_POSTS_PAGE,
    SINGLE_POST_PAGE,
    ADD_HOTEL_PAGE,
    DOCTOR_SETTINGS_PAGE,
    SUCCESS_REGISTER,
    ARTICLES_PAGE,
    EDIT_ARTICLES_PAGE,
    VIEW_ARTICLES_PAGE,
    PROFILE_QUESTIONS_PAGE,
    PROFILE_QUESTIONS_DETAILS,
    ADD_ARTICLES_PAGE,
    ANSWERED_QUESTIONS_PAGE,
    CHANGE_USER_PASSWORD_PAGE,
    VIEW_ALL_QUESTIONS_PAGE,
    QUESTIONS_DETAILS,
    MY_APPOINTMENT_PAGE,
} from "./settings/constant";

const Loading = () => null;

const AddListing = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "AddListing" */ "./container/AddListing/AddListing"
            ),
    loading: Loading,
    modules: ["AddListing"],
});

const AgentAccountSettingsPage = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "AgentAccountSettingsPage" */ "./container/Agent/AccountSettings/AgentAccountSettingsPage"
            ),
    loading: Loading,
    modules: ["AgentAccountSettingsPage"],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
    loader: () =>
        import(/* webpackChunkName: "NotFound" */ "./container/404/404"),
    loading: Loading,
    modules: ["NotFound"],
});

//User Login check here
const CustomRoute = ({ component: Component, ...rest }) => {
    const { loggedIn } = useContext(AuthContext);
    if (loggedIn) return <Route component={Component} {...rest} />;
    return <Redirect to={HOME_PAGE} />;
};

const IsVerified = ({ component: Component, props }) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let emailVerified, licenceDocument, approvedBy;

    if (userData) {
        emailVerified = userData.email_verified_status;
        licenceDocument = userData.licence_document_status;
        approvedBy = userData.approved_by_status;
    }
    // if (emailVerified && licenceDocument && approvedBy)
    return <Component {...props} />;

    // if (!emailVerified) {
    //   return <Redirect to={EMAIL_VERIFY_PAGE} />;
    // } else

    // if (!licenceDocument) {
    //   return <Redirect to={UPLOAD_PAGE} />;
    // } else
    // if (!approvedBy) {
    //   return <Redirect to={VERIFICATION_PAGE} />;
    // }
};

//User email verification check here
const ProtectedRoute = ({ component, ...rest }) => {
    const { loggedIn } = useContext(AuthContext);
    return (
        <Route
            render={(props) =>
                loggedIn ? (
                    <IsVerified component={component} props={props} />
                ) : (
                    <Redirect to={HOME_PAGE} />
                )
            }
            {...rest}
        />
    );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
    const { loggedIn } = useContext(AuthContext);
    return (
        <Layout>
            <Switch>
                <ProtectedRoute
                    exact
                    path={ADD_HOTEL_PAGE}
                    component={AddListing}
                />

                <ProtectedRoute
                    exact
                    path={DOCTOR_SETTINGS_PAGE}
                    component={AgentAccountSettingsPage}
                />

                <CustomRoute
                    exact
                    path={VERIFY_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Verify/Verify"),
                        loading: Loading,
                        modules: ["Verify"],
                    })}
                />
                {/* <CustomRoute
                exact
          path={EMAIL_VERIFY_PAGE}
          component={Loadable({
            loader: () => import("./container/Verify/Verify"),
            loading: Loading,
            modules: ["Verify"],
          })}
        /> */}
                <Route
                    exact
                    path={SUCCESS_REGISTER}
                    component={Loadable({
                        loader: () => import("./container/successRegistered"),
                        loading: Loading,
                        modules: ["success-registered"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={VERIFIED_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Verified/Verified"),
                        loading: Loading,
                        modules: ["Verified"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={UPLOAD_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Upload/Upload"),
                        loading: Loading,
                        modules: ["Uploading"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={VERIFICATION_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Verification/Verification"),
                        loading: Loading,
                        modules: ["Verification"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={APPROVE_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Approved/Approved"),
                        loading: Loading,
                        modules: ["Approved"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={DOCTOR_PROFILE_PAGE}
                    component={Loadable({
                        loader: () =>
                            import(
                                "./container/Agent/AccountSettings/AgentAccountSettingsPage"
                                ),
                        loading: Loading,
                        modules: ["AgentAccountSettingsPage"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={DOCTOR_DASHBOARD_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Dashboard/Dashboard"),
                        loading: Loading,
                        modules: ["Dashboard"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={DOCTOR_REVIEW_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/ProfileReviews/ProfileReviews"),
                        loading: Loading,
                        modules: ["ProfileReviews"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={MY_APPOINTMENT_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/MyAppointments/MyAppointments"),
                        loading: Loading,
                        modules: ["MyAppointments"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={ARTICLES_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/ArticlesList/ArticlesList"),
                        loading: Loading,
                        modules: ["ArticlesList"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={EDIT_ARTICLES_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/ArticlesList/EditArticle"),
                        loading: Loading,
                        modules: ["EditArticle"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={ADD_ARTICLES_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/ArticlesList/AddArticle"),
                        loading: Loading,
                        modules: ["AddArticle"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={VIEW_ARTICLES_PAGE+'/:slug'}
                    component={Loadable({
                        loader: () => import("./container/Agent/ArticlesList/ViewArticle"),
                        loading: Loading,
                        modules: ["ViewArticle"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={PROFILE_QUESTIONS_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/ProfileQuestions/ProfileQuestions"),
                        loading: Loading,
                        modules: ["ProfileQuestions"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={PROFILE_QUESTIONS_DETAILS + ':id'}
                    component={Loadable({
                        loader: () => import("./container/Agent/QuestionsReplies/QuestionDetails"),
                        loading: Loading,
                        modules: ["QuestionsReplies"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={ANSWERED_QUESTIONS_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/ProfileQuestions/AnsweredQuestions"),
                        loading: Loading,
                        modules: ["AnsweredQuestions"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={DOCTOR_PREVIEW_PAGE+ '/:id'}
                    component={Loadable({
                        loader: () =>
                            import("./container/Agent/AccountDetails/AgentDetailsViewPage"),
                        loading: Loading,
                        modules: ["AgentDetailsViewPage"],
                    })}
                />
                <Route
                    exact
                    path={CHANGE_USER_PASSWORD_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ChangePassword/ChangePassword"),
                        loading: Loading,
                        modules: ["ChangePassword"],
                    })}
                />

                <Route
                    exact
                    path={PRIVACY_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Privacy/Privacy"),
                        loading: Loading,
                        modules: ["Privacy"],
                    })}
                />
                <Route
                    exact
                    path={HOME_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Home/Home"),
                        loading: Loading,
                        modules: ["Home"],
                    })}
                />

                <Route
                    exact
                    path={`${SINGLE_POST_PAGE}/:slug`}
                    component={Loadable({
                        loader: () => import("./container/SinglePage/SinglePageView"),
                        loading: Loading,
                        modules: ["SinglePageView"],
                    })}
                />

                <Route
                    exact
                    path={LISTING_POSTS_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Listing/Listing"),
                        loading: Loading,
                        modules: ["Listing"],
                    })}
                />

                <Route
                    exact
                    path={VIEW_ALL_QUESTIONS_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ViewAllQuestions/ViewAllQuestions"),
                        loading: Loading,
                        modules: ["ViewAllQuestions"],
                    })}
                />


                <Route
                    exact
                    path={QUESTIONS_DETAILS + ':id'}
                    component={Loadable({
                        loader: () => import("./container/QuestionsReplies/QuestionDetails"),
                        loading: Loading,
                        modules: ["QuestionsReplies"],
                    })}
                />

                <Route component={NotFound} />
            </Switch>
        </Layout>
    );
};

export default Routes;