import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/UI/Container/Container';
import Heading from 'components/UI/Heading/Heading';

import SubscribeForm from './SubscribeForm'

import { Row, Col } from 'antd';
import FooterWrapper, {
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
} from './Footer.style';


import pay01 from '../../assets/images/pay.png';


const Footer = ({ logo, menu, copyright, className, path}) => {
  return (
    <>
      <FooterWrapper id="footer" className={className}>
        <Container>
          <Row>
            <Col span={24}>
              <Row>
                <Col xs={24} lg={6}>
                  <Row justify="center">
                    <Col span={24}>
                      {logo && logo}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={6} md={12}>
                  <Row>
                    <Col span={24}>
                      <Heading as="h4" content="CONTACT US" />
                      <p>
                          00971 43913570 <br/>
                          Level G, Building # 1 <br/>
                          Dubai Internet City, <br/>
                          Dubai, United Arab Emirates
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={6} md={12}>
                  <Row>
                    <Col span={24}>
                      <Heading as="h4" content="QUICK LINKS" />
                      {menu && <MenuWrapper>{menu}</MenuWrapper>}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={6}>
                  <Row>
                    <Heading as="h4" content="SUBSCRIBE" />
                    <p>
                      Subscribe to get updates and information.
                      Don't worry, we don't spam!
                    </p>

                    <SubscribeForm />

                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

      </FooterWrapper>
      <SecondaryFooter>
      <Container>
        <Row>
          <Col xs={24} lg={8}>
            <small>
                {copyright && <CopyrightArea>{copyright}</CopyrightArea>}
            </small>
          </Col>
          <Col xs={24} lg={8}>
            <p className="p-0">
              <span>Powered By</span>
              <a href="https://www.exalogic.co/"> Exalogic Consulting  </a>
            </p>
          </Col>
          <Col xs={24} lg={8} orientation="right">
            <img src={pay01} alt="Pay Tab" />
          </Col>
        </Row>
      </Container>
      </SecondaryFooter>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
