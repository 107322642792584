import React, { Component } from "react";
import { MailOutlined } from '@ant-design/icons';
import { notification } from 'antd';
/*import { useAlert  } from 'react-alert'*/
import {apiUrl} from "../../util/serverUtil";

export default class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            user_type_id: "2"
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const url = apiUrl + "Home/addsubscriber";
        return new Promise((resolve,reject) => {
            fetch(url,{
                method:'POST',
                body:JSON.stringify(this.state)
            })
                .then((response)=>response.json())
                .then((responseJson)=>{
                    resolve(responseJson);
                    if(responseJson.status == 'Success'){
                        notification['success']({
                            message: 'Thank you',
                            description: responseJson.message,
                            placement: 'bottomRight'
                        });
                    }else {
                        notification['error']({
                            message: 'Error',
                            description: responseJson.message,
                            placement: 'bottomRight'
                        });
                    }

            }).catch((error)=>{
                reject(error);
            })
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>

                <input type="email"
                       name="email"
                       placeholder="Email"
                       value={this.state.email}
                       onChange={this.handleChange}
                       required
                />

                <button type="submit"> <MailOutlined /> </button>

            </form>

        );
    }
}