import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import { HOME_PAGE, VIEW_ALL_QUESTIONS_PAGE } from 'settings/constant';
import { MenuWrapper } from 'components/Navbar/Navbar.style';

const AuthMenu = ({ className, isLogin = false }) => {
  // console.log("isLogin +++ ", isLogin)
  return (
    <MenuWrapper className="main_menu mobile-out">
      <Menu className={className}>
        <Menu.Item key="1" className="head-right head-qs">
          <NavLink to={VIEW_ALL_QUESTIONS_PAGE}> Questions </NavLink>
        </Menu.Item>
        {
          !isLogin &&
          <Menu.Item key="0" >
            <NavLink to={HOME_PAGE}> Login / Signup </NavLink>
          </Menu.Item>
        }
      </Menu>
    </MenuWrapper>
  );
};

export default AuthMenu;
