import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import {
    HOME_PAGE,
    DOCTOR_DASHBOARD_PAGE,
    DOCTOR_PROFILE_PAGE,
} from 'settings/constant';

const MobileMenu = ({ className }) => {
    // auth context
    const { loggedIn, logOut } = useContext(AuthContext);

    return (
        <Menu className={className}>
            <Menu.Item key="0">
                <a target={'blank'} href="https://patients.edentalportal.com/">For Patients</a>
            </Menu.Item>
            {/*<Menu.Item key="1">*/}
                {/*<a target={'blank'} href="http://www.edentalportal.com/">Licensing Exam</a>*/}
            {/*</Menu.Item>*/}
            {loggedIn && (
                <>
                <Menu.Item key="2">
                    <NavLink to={DOCTOR_DASHBOARD_PAGE}>  Dashboard </NavLink>
                </Menu.Item>
                <Menu.Item key="3">
                    <NavLink to={DOCTOR_PROFILE_PAGE}>My Profile</NavLink>
                </Menu.Item>
                </>
            )}

            {loggedIn && (
                <Menu.Item key="4">
                    <button onClick={logOut}>Log Out</button>
                </Menu.Item>
            )}
        </Menu>
    );
};

export default MobileMenu;
