import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';


const FooterWrapper = styled.footer`
  width: 100%;
  background-color: #314755;
  color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  padding: 47px 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index:11;

  @media only screen and (max-width: 480px) {
    padding: 60px 0 50px;
  }
  h4{
    color: #ffffff;
    font-size: 20px;
    Line-height: 24px;
    width: 100%;
  }
  p{
    font-size: 13px;
    Line-height: 26px;
    color: #c8d1d3;
    font-weight: 400;
    margin-top: 15px;
  }
  img{
    width:160px;
  }
  form{
    width: 85%;
    display: block;
    position: relative;
  }
    input[type=email] {
        display: inline-block;
        width: 100%;
        height: calc(2.25rem + 2px);
        padding: .375rem .75rem .375rem 20px;
        font-size: 14px;
        line-height: 1.5;
        color: rgba(255,255,255,.7);
        background: #000;
        border: 0;
        border-radius: 20px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        margin: 0;
        position: relative;
    }
    button {
        border: 0;
        border-radius: 50%;
        background: #097cec;
        color: #fff;
        width: 30px;
        height: 30px;
        margin: 4px;
        padding: 3px 8px;
        font-family: rift-soft,sans-serif;
        letter-spacing: .2rem;
        line-height: 21px;
        font-weight: 600;
        position: absolute;
        right: 2px;
        cursor: pointer;
    }
`;

export const MenuWrapper = styled.div`
  margin-top: 10px;

  @media only screen and (max-width: 480px) {
    margin-top: 10px;
    padding-left: 0;
  }
  
  ul,
  .ant-menu,
  ul.ant-menu {
    display: inline-block;
    float: left;
    align-items: center;
    border: 0;
    background-color: transparent;
    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    li {
      margin: 0 0;
      padding: 5px 0;
      height: auto;
      margin-bottom: 0 !important;
      font-weight: 400;
      @media only screen and (max-width: 480px) {
        margin: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.ant-menu-item-selected {
        background-color: transparent;
      }
      color: #c8d1d3;
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      transition: color 0.2s ease-in-out;

      a {
        color: ${themeGet('white.0', '#c8d1d3')};
        transition: color 0.2s ease-in-out;
        &:hover {
          color: ${themeGet('primary.0', '#007bff')};
        }
      }
    }
    .ant-menu-item:active, 
    .ant-menu-submenu-title:active{
        background-color: transparent;
        outline: none;
    }
  }
`;

export const SecondaryFooter = styled.div`
  background-color: #243f50;
  @media only screen and (min-width: 992px) {
    height: 74px;
  }
  p{
    color: #ffffffb5;
    padding: 13px 0px;
    text-align: center;
    margin-bottom:0px;    
  }
  @media only screen and (max-width: 991px) {
    .p-0 {padding: 0;}
  }
  img{
    margin-top: 8px;
    text-align: right;
    float: right;
    @media only screen and (max-width: 991px) {
        float: none;
        margin: 0 auto 15px;
        display: table;
    }
  }
`;

export const CopyrightArea = styled.div`
  color: rgba(255, 255, 255, 0.71);
    padding: 15px 0;
    font-size: 13px;
    line-height: 19px;
    margin: 0;
    @media only screen and (max-width: 991px) {
        text-align: center;
        padding-bottom: 0;
    }
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    margin-top: 20px;
  }
`;

export default FooterWrapper;
