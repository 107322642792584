import Axios from "axios";

export const Service = Axios.create({
  baseURL: "https://auth.edentalportal.com/api/",
  rejectUnauthorized: false,
  headers: {
    "Content-Type": "multipart/form-data;",
    Accept: 'application/json',
  }
});

// WEB URLS
// export const webUrl = 'https://dev.edentalportal.com/'; //dev url
 export const webUrl = 'https://www.edentalportal.com/'; //Live url

// LIVE SITE URLS
// export const liveSiteUrl = 'https://dev-patients.edentalportal.com/'; //dev url
 export const liveSiteUrl = 'https://patients.edentalportal.com/'; //Live url


// API URLS
//export const apiUrl = 'https://dev-api.edentalportal.com/api/'; //dev url
export const apiUrl = 'https://auth.edentalportal.com/api/'; //Live url
