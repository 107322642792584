import React, { useState, useEffect, useMemo } from "react";
import { Button, notification, Space } from "antd";
import { useHistory } from "react-router-dom";
import {apiUrl} from "../util/serverUtil";
import { Redirect } from "react-router-dom";
const axios = require("axios");
export const AuthContext = React.createContext();

const loginUrl = apiUrl + "Auth/login";
const registrationUrl =
    apiUrl + "Auth/registration";
const resendUrl =
    apiUrl + "Auth/resendVerificationMail";
const logoutUrl = apiUrl + "Auth/logout";

const checkProfileUrl =
    apiUrl + "admin/users/userdetails";

let userData = localStorage.getItem("userData");
let userToken = localStorage.getItem("token");

const AuthProvider = (props) => {
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const checkLoggedIn = () => {
    setUser(userData ? JSON.parse(userData) : {});
    setLoggedIn(userToken ? true : false);
  };

  const checkUserProfile = () => {
    let user_id = localStorage.getItem("user_id");
    if (user_id) {
      let params = {
        id: user_id,
        user_type_id: 2,
      };
      return new Promise((resolve, reject) => {
        fetch(checkProfileUrl, {
          method: "POST",
          body: JSON.stringify(params),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            resolve(responseJson);
            if (responseJson.status === "Success") {
              localStorage.setItem("user_id", responseJson.data.id);
              // localStorage.setItem("token", responseJson.token);
              localStorage.setItem(
                "userData",
                JSON.stringify(responseJson.data)
              );
              setLoggedIn(true);
              setUser(responseJson.data);
            }
            // if (responseJson.status === "Success") {
            //   if (responseJson.data.email_verified == false) {
            //     localStorage.setItem("user_id", responseJson.data.id);
            //     localStorage.setItem("token", responseJson.token);
            //     localStorage.setItem(
            //       "userData",
            //       JSON.stringify(responseJson.data)
            //     );
            //     setLoggedIn(true);
            //     setUser(responseJson.data);
            //     history.push("/verify");
            //   } else if (
            //     responseJson.data.licence_document == false &&
            //     responseJson.data.email_verified == true
            //   ) {
            //     localStorage.setItem("user_id", responseJson.data.id);
            //     localStorage.setItem("token", responseJson.token);
            //     localStorage.setItem(
            //       "userData",
            //       JSON.stringify(responseJson.data)
            //     );
            //     setLoggedIn(true);
            //     setUser(responseJson.data);
            //     history.push("/upload");
            //   } else {
            //     localStorage.setItem("user_id", responseJson.data.id);
            //     localStorage.setItem("token", responseJson.token);
            //     localStorage.setItem(
            //       "userData",
            //       JSON.stringify(responseJson.data)
            //     );
            //     setLoggedIn(true);
            //     setUser(responseJson.data);
            //     history.push("/dashboard");
            //   }
            // } else {
            //   notification["error"]({
            //     message: "Invalid Login",
            //     description: responseJson.message,
            //     placement: "bottomRight",
            //   });
            // }
            //localstorage code set here
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      setUser({});
      setLoggedIn(false);
      localStorage.clear();
    }
  };

  useMemo(() => {
    checkUserProfile();
    if (userToken && userData) {
      setUser(JSON.parse(userData));
      setLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (userToken && userData) {
      setUser(JSON.parse(userData));
      setLoggedIn(true);
    }
  }, [userData, userToken]);

  const signIn = (params) => {
    return new Promise((resolve, reject) => {
      fetch(loginUrl, {
        method: "POST",
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
          console.log(responseJson);
          if (responseJson.status === "Success") {
            localStorage.setItem("user_id", responseJson.data.id);
            localStorage.setItem("token", responseJson.token);
            localStorage.setItem("userData", JSON.stringify(responseJson.data));
            setLoggedIn(true);
            setUser(responseJson.data);
            if (responseJson.data.email_verified_status == false) {
              localStorage.setItem("user_id", responseJson.data.id);
              localStorage.setItem("token", responseJson.token);
              localStorage.setItem(
                "userData",
                JSON.stringify(responseJson.data)
              );
              setLoggedIn(true);
              setUser(responseJson.data);
              history.push("/verify");
            } else if (
              responseJson.data.licence_document_status == false ||
              responseJson.data.approved_by_status == false
            ) {
              localStorage.setItem("user_id", responseJson.data.id);
              localStorage.setItem("token", responseJson.token);
              localStorage.setItem(
                "userData",
                JSON.stringify(responseJson.data)
              );
              setLoggedIn(true);
              setUser(responseJson.data);
              history.push("/verify");
            } else {
              localStorage.setItem("user_id", responseJson.data.id);
              localStorage.setItem("token", responseJson.token);
              localStorage.setItem(
                "userData",
                JSON.stringify(responseJson.data)
              );
              setLoggedIn(true);
              setUser(responseJson.data);
              history.push("/dashboard");
            }
          } else {
            notification["error"]({
              message: "Invalid Login",
              description: responseJson.message,
              placement: "bottomRight",
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const signUp = (params) => {
    if(params){
      params.role_id = "2";
      params.user_type_id = "2";
      params.t_n_c = "1";
    }
    /*axios({
          method: "POST",
          url: registrationUrl,
          headers: {
            "Content-Type": "multipart/form-data;",
          },
          data: params,
        })*/
    return new Promise((resolve, reject) => {
      fetch(registrationUrl, {
        method: "POST",
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
          if (response.status == "Success") {
            localStorage.setItem("user_id", response.data.id);
            localStorage.setItem("token", response.token);
            localStorage.setItem("userData", JSON.stringify(response.data));
            setLoggedIn(true);
            setUser(response.data);
            history.push("/verify");
          } else {
            notification["error"]({
              message: "Invalid Registration",
              description: response.message,
              placement: "bottomRight",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      /* history.push(`/`);*/
    });
  };

  // const logOut = () => {
  //     setUser({});
  //     setLoggedIn(false);
  //     localStorage.clear();
  //     notification["success"]({
  //         message: "Logout",
  //         description: "Logout successfully",
  //         placement: "bottomRight",
  //     });
  // };

  const logOut = () => {
    return new Promise((resolve, reject) => {
      let Bearer = "Bearer " + localStorage.getItem("token");

      let myHeaders = new Headers();
      myHeaders.append("Authorization", Bearer);

      let requestOptions = {
        mode: "no-cors",
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(loginUrl, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          notification["success"]({
            message: "Logout",
            description: "Logged out!",
            placement: "bottomRight",
          });
          setUser({});
          setLoggedIn(false);
          localStorage.clear();
          resolve();
        })
        .catch((error) => {
          notification["error"]({
            message: "Invalid",
            description: "Failed to Logout",
            placement: "bottomRight",
          });
        });
    });
  };

  const reSendVerification = (params) => {
    params.user_id = localStorage.getItem("user_id");
    return new Promise((resolve, reject) => {
      fetch(resendUrl, {
        method: "POST",
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
          if (responseJson.status === "Success") {
            notification["success"]({
              message: responseJson.message,
              description: responseJson.message,
              placement: "bottomRight",
            });
          } else {
            notification["error"]({
              message: responseJson.message,
              description: responseJson.message,
              placement: "bottomRight",
            });
          }
          //localstorage code set here
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        checkLoggedIn,
        reSendVerification,
        checkUserProfile,
        user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
